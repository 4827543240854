
import {computed, defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import {useGroupStore} from "@/store/group.store";
import ImageUpload from "@/components/base/upload/ImageUpload.vue";
import GroupService from "@/core/services/GroupService";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Codes from "@/views/code/Codes.vue";
import InfoBox from "@/components/base/InfoBox.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";
import {ModelAddress} from "@/core/entity/IAddress";
import AddressSelect from "@/components/base/select/AddressSelect.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {COMPANY_SIZE, INDUSTRY} from "@/core/config/Constant";

export default defineComponent({
  name: "Setting",
  components: {BaseSelect, AddressSelect, HelpEmbed, InfoBox, Codes, ImageUpload, FormErrorMessage, BaseForm, Field},
  setup() {
    const submitting = ref(false);
    const groupStore = useGroupStore();
    const model = ref<any>({name: '', logo: '', companyConfig: {}});
    const group = computed<any>(() => groupStore.entity);
    model.value = {
      name: group.value?.name,
      logo: group.value?.logo,
      companyConfig: group.value.companyConfig ? group.value.companyConfig :
        {
          website: '',
          phone: '',
          address: new ModelAddress(),
          size: 'SIZE_1_10',
          industry: 'I_1',
        }
    }
    watch(group, cb => {
      console.log(cb);
      model.value = {
        name: cb.name, logo: cb.logo, companyConfig: cb.companyConfig ? cb.companyConfig : {
          website: '',
          phone: '',
          address: new ModelAddress(),
          size: 'SIZE_1_10',
          industry: 'I_1',
        }
      }
    })
    return {
      model,
      submitting,
      group,
      COMPANY_SIZE,
      INDUSTRY,
      groupStore,
    }
  },
  methods: {
    save() {
      this.submitting = true;
      GroupService.update(this.group.id, this.model).then(res => {
        this.groupStore.setEntity(res);
      }).finally(() => {
        store.dispatch(Actions.LOAD_PROFILE)
        this.submitting = false;
      })
    }
  }
})
