
import {computed, defineComponent, ref} from "vue";
import {useCodeStore} from "@/store/code.store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {LoadPanel, PinaFilterObjects} from "@/core/composite/composite";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import CodeForm from "@/views/code/CodeForm.vue";
import Swal from "sweetalert2";
import CodeService from "@/core/services/CodeService";

export default defineComponent({
  name: "Codes",
  components: {CodeForm, BaseModal, KtDatatable, QuickAction},
  setup() {
    const codeStore = useCodeStore()
    const state = ref<any>({name: 'Add Code', id: '', code: {name: '', parentIdRequest: ''}})
    const page = computed(() => codeStore.page);
    const headers = [
      {
        name: 'Name',
        key: 'name',
        sortable: false
      },

      {
        name: '',
        key: 'actions',
        sortable: false
      }
    ];
    return {
      state,
      headers,
      page,
      ...LoadPanel(),
      ...PinaFilterObjects(codeStore, {noneParent: true}, []),
    }
  },
  methods: {

    onAdd() {
      this.state.name = 'Add Code';
      this.state.id = ''
      this.state.code = {name: '', parentIdRequest: ''}
      const modal = this.$refs.formCodeRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onEdit(entity) {
      this.state.name = 'Edit code'
      this.state.id = entity.id
      this.state.code = {name: entity.name, parentIdRequest: entity.parent}
      const modal = this.$refs.formCodeRef as typeof BaseModal;
      modal.showBaseModal();
    },

    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await CodeService.delete(id).then(() => {
            this.paginationDataLoad()
          })
        }
      })
    },
    onSave() {
      this.paginationDataLoad()
      const base = this.$refs.formCodeRef as typeof BaseModal
      base.hideBaseModal();
    },
  }
})
