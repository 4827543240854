
import {defineComponent, ref, watch} from "vue";
import LocationService from "@/core/services/LocationService";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {Field} from "vee-validate";

export default defineComponent({
  name: "AddressSelect",
  components: {BaseSelect, Field},
  props: {
    showLine: {default: false},
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const countries = ref<any>([]);
    const states = ref<any>([])
    const loadCountry = () => {
      LocationService.countries().then(res => {
        countries.value = res;
      })
    }
    const loadState = () => {
      LocationService.getStates().then(res => {
        states.value = res;
      })
    }
    const model = ref<any>(props.modelValue);
    watch(() => props.modelValue, cb => {
      model.value = cb;
    })
    watch(() => model, cb => {
      emit('update:modelValue', cb)
    })
    loadCountry();
    loadState();
    return {
      states,
      countries,
      model,
    }
  },
  methods: {
    onCountryNameSelect(countryName) {
      this.model.stateCode = ''
      this.model.stateName = ''
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name) this.model.countryName = name;
    },

    findState(country) {
      return this.states.filter(item => item.country === country)
    }
  }
})
