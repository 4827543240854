import {defineStore} from "pinia";
import {useLoadingStore} from "@/store/loading";
import CodeService from "@/core/services/CodeService";


export const useCodeStore = defineStore('codeStore', {
  state: () => ({
    page: <any>{
      data: [],
      total: 0,
      totalPages: 0,
    }
  }),
  actions: {
    list(filter) {
      const loadingStore = useLoadingStore();
      loadingStore.setEntitiesLoading(true);
      CodeService.findAll<any>(filter).then(res => {
        this.page = res;
      }).finally(() => {
        loadingStore.setEntitiesLoading(false)
      })
    }
  }
})