export interface IAddress {
  countryCode: string;
  countryName: string;
  stateCode: string;
  stateName: string;
  cityName: string;
  street: string;
}


export class ModelAddress implements IAddress {
  cityName = '';
  countryCode = '';
  countryName = '';
  stateCode = '';
  stateName = '';
  street = '';
}