import {BaseService} from "@/core/services/BaseService";

class LocationService extends BaseService<any> {
  endpoint = "/location"

  async countries() {
    return await this.http().get(this.endpoint + "/countries").then(res => {
      return res.data
    })
  }

  async getStates() {
    return await this.http().get(this.endpoint + "/states").then(res => {
      return res.data
    })
  }
}

export default new LocationService();